/* eslint-disable no-param-reassign */
import type { TFunction } from 'react-i18next'
import type { Flat } from 'types/flat'

type Props<T extends Flat> = {
  flat: T | T[] | null
  investment?: string | null
  t: TFunction<string | string[]>
}

const getInitFormMessage = <T extends Flat>({
  flat,
  investment,
  t,
}: Props<T>) => {
  if (!flat && investment) {
    const message = `${t('imInterestedIn')} ${investment}${t(
      'messageInvestment'
    )}. ${t('contactMe')}`
    return message
  }
  if (flat && !(flat instanceof Array) && flat.name && flat.investment) {
    const message = `${t('imInterestedIn')} ${flat.type.toLowerCase()} ${t(
      'nr'
    )} ${flat.name} ${t('invLocative')} ${flat.investment}${t(
      'messageInvestment'
    )}. ${t('contactMe')}`
    return message
  }
  if (flat && flat instanceof Array && flat.length > 0) {
    const type = flat[0].type === 'unit' ? t('unit') : t('flat')
    // @ts-ignore
    const groupedFlats: [string, T[]][] = Object.entries(
      flat.reduce((r, a) => {
        // @ts-ignore
        r[a.investment] = [...(r[a.investment] || []), a]
        return r
      }, {} as T)
    )
    const message = `${t('imInterestedIn')} ${groupedFlats.reduce(
      (r, a, index) => {
        const invString = `${a[1]
          .map((el) => `${type} ${t('flat:nr')} ${el.name}`)
          .join(', ')} ${t('invLocative')} ${a[0]}${t('messageInvestment')}${
          index === groupedFlats.length - 1 ? '.' : ', '
        }`
        return r + invString
      },
      ''
    )} ${t('contactMe')}`

    return message
  }
  return ``
}

export default getInitFormMessage
