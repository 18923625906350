import React, { useState, useRef, useEffect } from 'react'
import { useLocalization } from 'gatsby-theme-i18n'
import { useTranslation } from 'react-i18next'
import { Formik, Field, Form, FormikProps } from 'formik'

import { Heading } from 'components/shared/Typography'
import Button from 'components/shared/Button'
import Icon from 'components/shared/Icon'
import { Row, Col } from 'components/shared/Grid'

import Input from 'components/layout/Forms/components/Input'
import Info from 'components/layout/Forms/components/Info'

import {
  Wrapper,
  HeadingWrapper,
  IconWrapper,
  ButtonWrapper,
} from 'components/layout/Forms/FlatForm/components'
import Terms from 'components/layout/Forms/Terms'

import { FORM_INIT_VALUES, FORM_SCHEMA, DEFAULT_INFO } from 'constants/form'

import useGraphqlQuery from 'hooks/useGraphqlQuery'

import type { FormID, FormInfo, FormValues } from 'types/form'
import type { Flat } from 'types/flat'

import getSendFormMutation, {
  getStatusParentKey,
} from 'components/layout/Forms/utils/getSendFormMutation'
import getInitFormMessage from 'components/layout/Forms/utils/getInitFormMessage'

type Props = {
  formID: FormID
  flat: Flat | Flat[]
  heading?: string
  messageReadonly?: boolean
  icon?: string
}

const FlatForm: React.FC<Props> = ({
  formID,
  flat,
  messageReadonly = false,
  heading,
  icon,
}) => {
  const [formInfo, setFormInfo] = useState<FormInfo>(DEFAULT_INFO)

  const ref = useRef<FormikProps<FormValues>>(null)

  const { t } = useTranslation('form')
  const { locale } = useLocalization()

  useEffect(() => {
    const form = ref.current
    if (form && flat) {
      const message = getInitFormMessage({ flat, t })

      form.resetForm()
      form.setFieldValue('message', message)
    }
  }, [flat, locale])

  const { loading, request } = useGraphqlQuery({
    query: getSendFormMutation(formID),
    statusKey: getStatusParentKey(formID),
    onError: () => setFormInfo({ error: true, text: t('formError') }),
    onSuccess: () => {
      setFormInfo({
        error: false,
        text: t('formSent'),
      })
    },
  })

  return (
    <Wrapper>
      <HeadingWrapper>
        {icon && (
          <IconWrapper>
            <Icon src={icon} size={24} />
          </IconWrapper>
        )}
        <Heading
          as="h3"
          size={24}
          weight={500}
          sizeDiff={0.2}
          dangerouslySetInnerHTML={{
            __html: heading || t('flatFormHeading'),
          }}
          margin="0"
        />
      </HeadingWrapper>
      <Formik
        innerRef={ref}
        initialValues={FORM_INIT_VALUES}
        validationSchema={FORM_SCHEMA}
        onSubmit={async (values: FormValues) => {
          if (!formInfo.text || formInfo.error) {
            // await request({
            //   ...values,
            //   investment:
            //     flat instanceof Array ? flat[0].investment : flat.investment,
            // })
            await request(values)
            setFormInfo({
              error: false,
              text: t('formSent'),
            })
          }
        }}
      >
        {({ errors, touched }) => (
          <Form id={formID}>
            <Row>
              <Col xs={24}>
                <Field
                  name="name"
                  label={t('name')}
                  component={Input}
                  required
                />
              </Col>
              <Col xs={24}>
                <Field
                  name="email"
                  label={t('email')}
                  component={Input}
                  required
                />
              </Col>
              <Col xs={24}>
                <Field
                  name="phone"
                  label={t('phone')}
                  component={Input}
                  required
                />
              </Col>
              <Col xs={24}>
                <Field
                  name="message"
                  label={t('message')}
                  textarea
                  readonly={messageReadonly}
                  component={Input}
                />
              </Col>
            </Row>
            <Terms errors={errors} touched={touched} />
            <Info info={formInfo} />
            <ButtonWrapper>
              <Button
                disabled={!!(formInfo.text && !formInfo.error)}
                loading={loading}
                width="100%"
              >
                {t('sendMessage')}
              </Button>
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export default FlatForm
