import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/Container'
import { Row, Col } from 'components/shared/Grid'
import { Heading, HighlightedText } from 'components/shared/Typography'

import SingleFlatCard from 'components/layout/Flat/Browser/Tiles/SingleCard/'

import useBreakpoint from 'hooks/useBreakpoint'

import type { Flat } from 'types/flat'

type Props = {
  title: string
  flats: Flat[]
}

const Section = styled.section`
  position: relative;
  width: 100%;
  padding: 5rem 0;
  ${({ theme }) => theme.media.lg.min} {
    ${Heading} {
      margin-bottom: 3rem;
    }
  }
  ${({ theme }) => theme.media.sm.max} {
    padding: 3rem 0;
  }
`

const SimilarFlats: React.FC<Props> = ({ title, flats }) => {
  const { lg, xxl } = useBreakpoint()

  const MAX = lg && !xxl ? 3 : 4

  return (
    <Section>
      <Container slim>
        <Heading
          as="h2"
          size={50}
          sizeDiff={0.7}
          weight={500}
          align="center"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {flats.length > 0 ? (
          <Row alignItems="stretch">
            {flats.slice(0, MAX).map((flat) => (
              <Col key={flat.link} xs={24} sm={12} lg={8} xxl={6}>
                <SingleFlatCard flat={flat} />
              </Col>
            ))}
          </Row>
        ) : (
          <HighlightedText>Brak wyników</HighlightedText>
        )}
      </Container>
    </Section>
  )
}

export default SimilarFlats
