import React, { useState } from 'react'
import styled from 'styled-components'

import Container from 'components/shared/Container'
import { Heading, Text } from 'components/shared/Typography'
import { Flex } from 'components/shared/Grid'
import FavouriteButton from 'components/shared/Button/Favourite'
import Switch from 'components/shared/Switch'
import LoadedImage from 'components/shared/LoadedImage'

import FlatData from 'components/layout/Flat/Header/FlatData'
import FlatForm from 'components/layout/Forms/FlatForm'
import FlatTourModal from 'components/layout/Flat/Header/TourModal'

import type { Flat } from 'types/flat'

type Props = {
  flat: Flat
}

const Wrapper = styled.header`
  padding: 4rem 0;
  background: ${({ theme }) => theme.colors.white50};
  ${({ theme }) => theme.media.xxl.max} {
    & > ${Container} {
      max-width: ${({ theme }) =>
        `calc(${theme.container.width} + (${theme.container.padding} * 2))`};
    }
  }
  ${({ theme }) => theme.media.md.max} {
    padding: 2rem 0 0;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.media.xl.max} {
    flex-direction: column;
  }
`

const MainContent = styled.div`
  width: 100%;
`

const FormWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  min-width: 375px;
  margin-left: 1.5rem;
  flex: 1;
  ${({ theme }) => theme.media.xl.max} {
    margin: 2rem auto;
    min-width: initial;
    max-width: 700px;
  }
  ${({ theme }) => theme.media.xs.max} {
    margin: 0 0 0 -1.5rem;
    width: calc(100% + 3rem);
  }
`

const FavButtonWrapper = styled.div`
  margin-left: 1rem;
  & > button {
    position: relative;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${({ theme }) => theme.colors.white};
      z-index: -1;
      transform: rotate(45deg);
      transition: 0.25s;
    }
    &:hover:before {
      background: ${({ theme }) => theme.colors.primary100};
    }
    svg {
      width: 17px;
      height: 16px;
    }
  }
`

const SwitchWrapper = styled.div`
  & > div {
    margin: 0;
  }
  ${({ theme }) => theme.media.sm.max} {
    margin-top: 1.5rem;
  }
`

const Image = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  min-height: 600px;
  max-height: 600px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: contain;
    max-height: 600px;
  }
  ${({ theme }) => theme.media.md.max} {
    min-height: 290px;
    max-height: 290px;
    img {
      max-height: 290px;
    }
  }
`

const HeadingFlex = styled(Flex)`
  ${({ theme }) => theme.media.sm.max} {
    flex-direction: column;
    & > ${Flex} {
      justify-content: space-between;
      width: 100%;
    }
  }
`

const MainContentFlex = styled(Flex)`
  ${({ theme }) => theme.media.md.max} {
    flex-direction: column-reverse;
    padding-bottom: 2rem;
  }
`

const FlatHeader: React.FC<Props> = ({ flat }) => {
  const [tourVisible, setTourVisible] = useState(false)
  const [activeImage, setActiveImage] = useState<'2D' | '3D'>(
    flat.image3D ? '3D' : '2D'
  )

  return (
    <>
      <Wrapper>
        <Container slim>
          <InnerWrapper>
            <MainContent>
              <HeadingFlex
                justifyContent="space-between"
                alignItems="center"
                margin="0 0 1rem"
              >
                <Flex alignItems="center">
                  <Heading as="h1" size={40} weight={500} margin="0">
                    {flat.type} nr {flat.name}
                  </Heading>
                  <FavButtonWrapper>
                    <FavouriteButton flat={flat} />
                  </FavButtonWrapper>
                </Flex>
                {!!(flat.image3D && flat.image2D) && (
                  <SwitchWrapper>
                    <Switch
                      active={activeImage}
                      setMode={setActiveImage}
                      modes={[
                        { label: '2D', value: '2D' },
                        { label: '3D', value: '3D' },
                      ]}
                    />
                  </SwitchWrapper>
                )}
              </HeadingFlex>
              <MainContentFlex>
                <FlatData showTour3D={() => setTourVisible(true)} flat={flat} />
                <Image>
                  {flat.image2D || flat.image3D ? (
                    <LoadedImage
                      src={flat[`image${activeImage}`] as 'image2D' | 'image3D'}
                      src2={flat.image2D || ''}
                      alt={flat.name}
                      onError={() => setActiveImage('2D')}
                    />
                  ) : (
                    <Text>Brak podglądu</Text>
                  )}
                </Image>
              </MainContentFlex>
            </MainContent>
            <FormWrapper>
              <FlatForm
                heading="Zapytaj o mieszkanie"
                flat={flat}
                formID="flat-form"
              />
            </FormWrapper>
          </InnerWrapper>
        </Container>
      </Wrapper>
      {flat.tour3D && (
        <FlatTourModal
          active={tourVisible}
          flat={flat}
          close={() => setTourVisible(false)}
        />
      )}
    </>
  )
}

export default FlatHeader
