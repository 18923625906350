import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Text } from 'components/shared/Typography'
import { Col, Row } from 'components/shared/Grid'
import Button from 'components/shared/Button'
import Icon from 'components/shared/Icon'

import formatPrice from 'utils/formatPrice'

import areaIcon from 'assets/icons/flat-area.svg'
import floorIcon from 'assets/icons/flat-floor.svg'
import priceIcon from 'assets/icons/flat-price.svg'
import roomIcon from 'assets/icons/flat-room.svg'
import downloadIcon from 'assets/icons/download.svg'
import tourIcon from 'assets/icons/tour-3d.svg'

import type { Flat, FlatData as Data } from 'types/flat'

type Props = {
  flat: Flat
  showTour3D: () => void
}

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  min-width: 260px;
  max-width: 450px;
  ${({ theme }) => theme.media.md.min} {
    max-width: 260px;
    & > ${Row} > ${Col}:not(:last-child) {
      border-bottom: 1px solid #dbdbdb;
    }
  }
  ${({ theme }) => theme.media.md.max} {
    margin: 0 auto;
    & > ${Row} > ${Col}:not(:nth-last-child(2)):not(:last-child) > div {
      border-bottom: 1px solid #dbdbdb;
      padding-bottom: 1rem;
    }
  }
`

const DataWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  ${({ theme }) => theme.media.md.max} {
    flex-direction: column;
    justify-content: center;
  }
`

const DataInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.md.max} {
    justify-content: center;
    align-items: center;
    & > p {
      font-size: 24px;
    }
  }
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  max-width: 80px;
  height: 80px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.white};
  margin: 0 1rem 0 0;
  ${({ theme }) => theme.media.md.max} {
    margin: 0 0 0.5rem 0;
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    padding: 1rem;
    & > span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

const ButtonsWrapper = styled.div`
  width: 100%;
  margin-top: 0.5rem;
`

const FlatData: React.FC<Props> = ({ flat, showTour3D }) => {
  const { t } = useTranslation('flat')

  const FLAT_DATA: Data[] = useMemo(() => {
    let dataToDisplay: Data[] = [
      { label: 'Liczba pokoi', key: 'rooms', icon: roomIcon },
      { label: 'Powierzchnia', key: 'area', unit: 'm2', icon: areaIcon },
      { label: 'Piętro', key: 'floor', icon: floorIcon },
    ]
    if (
      flat.isPriceVisible &&
      flat.price &&
      flat.status !== 'sold' &&
      flat.status !== 'reserved'
    ) {
      dataToDisplay = [
        ...dataToDisplay,
        { label: 'Cena', key: 'price', unit: 'PLN', icon: priceIcon },
      ]
    }

    if (flat.gardenArea > 0) {
      const dataWithGardenArea = [...dataToDisplay]

      // Insert after flat area
      dataWithGardenArea.splice(2, 0, {
        label: 'Ogródek',
        key: 'gardenArea',
        unit: 'm2',
        icon: areaIcon,
      })

      dataToDisplay = dataWithGardenArea
    }

    return dataToDisplay
  }, [flat.isPriceVisible])

  return (
    <Wrapper>
      <Row>
        {FLAT_DATA.map(({ label, key, icon, unit = '' }) => {
          const value = flat[key]
          const isPrice = typeof value === 'number' && unit === 'PLN'
          return (
            <Col key={key} xs={12} md={24}>
              <DataWrapper>
                {icon && (
                  <IconWrapper>
                    <Icon src={icon} />
                  </IconWrapper>
                )}
                <DataInnerWrapper>
                  <Text size={28} weight={300} themecolor="primary">
                    {isPrice ? `${formatPrice(value)} ` : `${value} `}
                    {unit === 'm2' ? (
                      <>
                        M<sup>2</sup>
                      </>
                    ) : (
                      t(unit)
                    )}
                  </Text>
                  <Text as="span" size={14} weight={500} transform="uppercase">
                    {label}
                  </Text>
                </DataInnerWrapper>
              </DataWrapper>
            </Col>
          )
        })}
      </Row>
      <ButtonsWrapper>
        <Row gap="0.75rem">
          {flat.PDF && (
            <Col xs={24}>
              <Button
                as="a"
                href={flat.PDF}
                target="_blank"
                width="100%"
                outline
              >
                <Icon src={downloadIcon} marginRight />
                Pobierz kartę PDF
              </Button>
            </Col>
          )}
          {flat.tour3D && (
            <Col xs={24}>
              <Button width="100%" outline onClick={showTour3D}>
                <Icon src={tourIcon} marginRight />
                Wirtualny spacer
              </Button>
            </Col>
          )}
        </Row>
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default FlatData
