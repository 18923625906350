import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { LocalizedLink } from 'gatsby-theme-i18n'

import Button from 'components/shared/Button'
import Icon from 'components/shared/Icon'
import Loader from 'components/shared/Loader'
import { Text, WithLine } from 'components/shared/Typography'

import toggleBodyOverflow from 'utils/toggleBodyOverflow'

import closeIcon from 'assets/icons/close.svg'
import artbudLogo from 'assets/icons/artbud-logo.svg'

import type { Flat } from 'types/flat'

type Props = {
  active: boolean
  flat: Flat
  close: () => void
}

const Wrapper = styled.div<{ active: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  opacity: 0;
  visibility: hidden;
  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

const Topbar = styled.nav`
  display: none;
  ${({ theme }) => theme.media.md.min} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
    height: 70px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  }
  ${({ theme }) => theme.media.xxl.min} {
    height: ${({ theme }) => theme.navigation.desktop};
  }
`

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
`

const LogoWrapper = styled.div`
  ${({ theme }) => theme.media.lg.max} {
    max-width: 160px;
  }
`

const Logo = styled(LocalizedLink)`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`

const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
`

const Main3D = styled.iframe`
  width: 100%;
  height: 100%;
`

const Close = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 10;
  & > ${Button} {
    &:hover {
      background: ${({ theme }) => theme.colors.primary};
    }
  }
  ${({ theme }) => theme.media.md.min} {
    display: none;
  }
`

const TourModal: React.FC<Props> = ({ active, flat, close }) => {
  const [isLoading, setIsLoading] = useState(true)

  const handleOnLoad = () => {
    if (isLoading) setTimeout(() => setIsLoading(false), 350)
  }

  useEffect(() => {
    toggleBodyOverflow(active)
  }, [active])

  return (
    <>
      {flat.tour3D && (
        <Wrapper active={active}>
          <Close>
            <Button round roundSize="46px" onClick={close}>
              <Icon src={closeIcon} />
            </Button>
          </Close>
          <Topbar>
            <InnerWrapper>
              <LogoWrapper>
                <Logo to="/">
                  <Icon src={artbudLogo} alt="ART-BUD" />
                </Logo>
              </LogoWrapper>
              <WithLine>
                <Text weight={500} size={22}>
                  {flat.type} {flat.name}
                </Text>
              </WithLine>
            </InnerWrapper>
            <Button primary onClick={close}>
              Powrót
            </Button>
          </Topbar>
          <MainWrapper>
            {(active || !isLoading) && (
              <Main3D src={flat.tour3D} onLoad={handleOnLoad} />
            )}
            {active && isLoading && <Loader active />}
          </MainWrapper>
        </Wrapper>
      )}
    </>
  )
}

export default TourModal
