import React from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/Layout'
import SEO from 'components/layout/SEO'
import Breadcrumbs from 'components/layout/Breadcrumbs'

import FlatHeader from 'components/layout/Flat/Header'
import SimilarFlats from 'components/layout/Flat/SimilarFlats'

import type { FlatTemplateQuery } from 'types/graphql'
import type { Flat } from 'types/flat'

const FlatTemplate: React.FC<PageProps<FlatTemplateQuery>> = ({ data }) => {
  const FLAT = data.FLAT as Flat

  return (
    <Layout>
      <SEO />
      <Breadcrumbs
        crumbs={[
          { name: FLAT.investment, to: `/${FLAT.investmentSlug}` },
          { name: `${FLAT.type} ${FLAT.name}` },
        ]}
      />
      <main>
        <FlatHeader flat={FLAT} />
        {!!(FLAT.similar && FLAT.similar.length > 0) && (
          <SimilarFlats title="Podobne mieszkania" flats={FLAT.similar} />
        )}
      </main>
    </Layout>
  )
}

export const query = graphql`
  query FlatTemplate($link: String) {
    FLAT: flats(link: { eq: $link }) {
      ...SingleFlatFragment
    }
  }
`

export default FlatTemplate
