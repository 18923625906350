import styled from 'styled-components'

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 2rem 1.5rem 1rem;
`

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 46px;
  min-height: 46px;
  max-height: 46px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  margin-right: 0.5rem;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  ${({ theme }) => theme.media.md.min} {
    & > button {
      height: 60px;
    }
  }
`
